<template>
  <div class="game">
    <v-container class="tw-flex tw-flex-col tw-h-full tw-p-0" v-if="game">
      <div class="game__top tw-p-4 sm:tw-p-6">
        <div class="game__top__title tw-text-sm tw-font-semibold text-center tw-mb-2 sm:tw-mb-4">
          {{
            $t('label.steps', {
              first: currentStep + 1,
              last: game[currentLocale].steps.length,
            })
          }}
        </div>
        <v-progress-linear
          :value="progressValue"
          height="6"
          background-color="stepperBackground"
          color="stepperColor"
          rounded
        ></v-progress-linear>
      </div>
      <div
        class="game__content tw-h-full tw-px-4 tw-my-4 sm:tw-px-6 sm:tw-my-6 tw-overflow-y-auto tw-overflow-x-hidden"
      >
        <div
          class="game__content__step__question"
          :class="[j === 0 ? 'tw-mt-0' : 'tw-mt-2']"
          v-for="(question, j) in game[currentLocale].steps[currentStep].questions"
          :key="`question-${j}`"
        >
          <div class="game__content__step__question__title tw-text-lg tw-font-semibold">
            {{ computedQuestion(question) }}
          </div>
          <div class="game__content__step__question__answer">
            <div class="game__content__step__question__answer__description tw-mt-2" v-if="question.description">
              {{ question.description }}
            </div>
            <InputRadio
              v-if="question.type === 'radio'"
              :value="feedback[question.id]"
              :question="question"
              :is-dark="isDark"
              :validating="validating"
              @change="setFeedback"
            />
            <InputCheckbox
              v-if="question.type === 'checkbox'"
              :value="feedback[question.id]"
              :question="question"
              :is-dark="isDark"
              :validating="validating"
              @change="setFeedback"
            />
            <InputTextarea
              v-if="question.type === 'textarea'"
              class="tw-mt-2"
              :value="feedback[question.id]"
              :question="question"
              :validating="validating"
              @change="setFeedback"
            />
            <InputTextfield
              v-if="question.type === 'textfield'"
              class="tw-mt-2"
              :value="feedback[question.id]"
              :question="question"
              :validating="validating"
              @change="setFeedback"
            />
            <InputDatePicker
              v-if="question.type === 'datepicker'"
              :value="feedback[question.id]"
              :question="question"
              :validating="validating"
              @change="setFeedback"
            />
            <InputDropdown
              v-if="question.type === 'dropdown'"
              class="tw-mt-2"
              :value="feedback[question.id]"
              :question="question"
              :validating="validating"
              @change="setFeedback"
            />
            <InputReview
              v-if="question.type === 'review'"
              class="tw-mt-2"
              :value="feedback[question.id]"
              :question="question"
              :validating="validating"
              @change="setFeedback"
            />
            <Identity
              v-if="question.type === 'identity'"
              :values="identity"
              :question="question"
              :is-dark="isDark"
              :validating="validating"
              @change="setIdentity"
            />
          </div>
        </div>
      </div>
      <div class="game__actions tw-p-4 sm:tw-p-6 tw-flex tw-items-center tw-justify-between">
        <div>
          <v-btn
            :dark="isDark"
            large
            depressed
            outlined
            color="secondary"
            v-if="currentStep > 0"
            class="tw-justify-self-start"
            @click="currentStep = currentStep - 1"
          >
            {{ $t('label.prev') }}
          </v-btn>
        </div>
        <div>
          <v-btn
            :dark="isDark"
            large
            depressed
            color="primary"
            class="tw-justify-self-end"
            v-if="currentStep < game[currentLocale].steps.length - 1"
            @click="validStep"
          >
            {{ $t('label.next') }}
          </v-btn>
          <v-btn
            :dark="isDark"
            large
            depressed
            color="primary"
            class="tw-justify-self-end"
            v-if="currentStep === game[currentLocale].steps.length - 1"
            :disabled="loading"
            :loading="loading"
            @click="validFinalStep"
          >
            {{ $t('label.seeMyPrize') }}
          </v-btn>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { empty } from '@/utils/object.util'
import { mapActions, mapState } from 'vuex'
import { sleep } from '@/utils/object.util'
import InputRadio from '@/components/Input/Radio.vue'
import InputCheckbox from '@/components/Input/Checkbox.vue'
import InputTextarea from '@/components/Input/Textarea.vue'
import InputTextfield from '@/components/Input/Textfield.vue'
import InputDatePicker from '@/components/Input/DatePicker.vue'
import InputDropdown from '@/components/Input/Dropdown.vue'
import InputReview from '@/components/Input/Review.vue'
import Identity from '@/components/Identity.vue'

export default {
  name: 'Feedback',
  components: {
    InputRadio,
    InputCheckbox,
    InputTextarea,
    InputTextfield,
    InputDatePicker,
    InputDropdown,
    InputReview,
    Identity,
  },
  props: {
    theme: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    currentStep: 0,
    validating: false,
    feedback: {},
    identity: {},
  }),
  async created() {
    if (this.game === null) {
      window.location.replace(`${window.location.origin}/${this.$route.params.code}`)
      await sleep(2000)
    } else {
      await this.incrementStat('gameStarted')
    }
  },
  computed: {
    ...mapState({
      game: state => state.global.game,
      currentLocale: state => state.global.currentLocale,
      connect: state => state.global.connect,
    }),
    progressValue() {
      return (100 / this.game[this.currentLocale].steps.length) * (this.currentStep + 1)
    },
    isDark() {
      return this.theme.game.dark
    },
    isNotValid() {
      const requiredQuestions = this.game[this.currentLocale].steps[this.currentStep].questions.filter(
        question => question.required && question.type !== 'identity'
      )
      return requiredQuestions.some(
        question =>
          empty(this.feedback[question.id]) ||
          (question.min ? this.feedback[question.id] && this.feedback[question.id].length < question.min : false)
      )
    },
    isIdentityNotValid() {
      const identityQuestion = this.identityQuestion
      const requiredFields = identityQuestion.fields.filter(field => field.required)
      return requiredFields.some(
        field =>
          empty(this.identity[field.key]) ||
          ((this.identity.country === 'US' || this.identity.country === 'CA') && empty(this.identity.state))
      )
    },
    isLastStepNotValid() {
      const identityQuestion = this.identityQuestion

      if (identityQuestion) {
        return this.isIdentityNotValid || this.isNotValid
      }
      return this.isNotValid
    },
    identityQuestion() {
      return this.game[this.currentLocale].steps[this.currentStep].questions.find(
        question => question.type === 'identity'
      )
    },
  },
  methods: {
    ...mapActions({
      getResult: 'global/getResult',
      incrementStat: 'global/incrementStat',
    }),
    computedQuestion(question) {
      if (question.required) {
        return `${question.title} *`
      }
      return question.title
    },
    setFeedback(payload) {
      this.feedback = {
        ...this.feedback,
        [payload.id]: typeof payload.value === 'string' ? payload.value.trim() : payload.value,
      }
    },
    setIdentity(payload) {
      this.identity = payload
    },
    validStep() {
      this.validating = true
      if (!this.isNotValid) {
        this.validating = false
        this.currentStep = this.currentStep + 1
      }
    },
    validFinalStep() {
      this.validating = true
      if (!this.isLastStepNotValid) {
        this.validating = false
        this.getPrize()
      }
    },
    async getPrize() {
      this.loading = true
      try {
        await this.getResult({
          feedback: this.feedback,
          identity: { ...this.identity },
          ...this.connect,
          locale: this.currentLocale.substring(0, 2),
        })
        this.$router.push({ name: 'Result' })
      } catch (e) {
        this.$emit('notification')
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.game {
  background-color: var(--theme-game-background);
  height: 100%;
  color: var(--theme-game-color);
}
</style>
