<template>
  <v-text-field
    v-if="field.key === 'lastname'"
    :value="value"
    color="input-color"
    background-color="input-background"
    filled
    flat
    :label="field.label"
    :type="field.type ? field.type : 'text'"
    :error-messages="valueErrors"
    @input="onInput($event, field.key)"
  ></v-text-field>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'IdentityLastName',
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    validating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [validationMixin],
  computed: {
    valueErrors() {
      const errors = []
      if (!this.validating) return errors
      !this.$v.value.required && errors.push(this.$t('error.required'))
      return errors
    },
  },
  methods: {
    onInput(e) {
      this.$emit('change', e)
    },
  },
  validations() {
    return {
      value: {
        required: requiredIf(() => {
          return this.field.required
        }),
      },
    }
  },
}
</script>
