<template>
  <div class="identity tw-mt-6">
    <div class="identity__field" v-for="(field, idx) in question.fields" :key="`identity-field-${idx}`">
      <IdentityFirstName
        v-if="field.key === 'firstname'"
        class="tw-mt-2"
        :field="field"
        :value="values.firstname"
        :validating="validating"
        @reset="onReset(field.key)"
        @change="onInput($event, field.key)"
      />
      <IdentityLastName
        v-if="field.key === 'lastname'"
        class="tw-mt-2"
        :field="field"
        :value="values.lastname"
        :validating="validating"
        @reset="onReset(field.key)"
        @change="onInput($event, field.key)"
      />
      <IdentityPhone
        v-if="field.key === 'phone'"
        class="tw-mt-2"
        :field="field"
        :value="values.phone"
        :validating="validating"
        @reset="onReset(field.key)"
        @change="onInput($event, field.key)"
      />
      <IdentityEmail
        v-if="field.key === 'email'"
        class="tw-mt-2"
        :field="field"
        :value="values.email"
        :validating="validating"
        @reset="onReset(field.key)"
        @change="onInput($event, field.key)"
      />
      <IdentityBirthday
        v-if="field.key === 'birthday'"
        :field="field"
        :value="values.birthday"
        :validating="validating"
        @change="onInput($event, field.key)"
      />
      <IdentityCountry
        v-if="field.key === 'country'"
        class="tw-mt-2"
        :user-country="userCountry"
        :countries="countries[currentLocale]"
        :field="field"
        :value="values.country || userCountry"
        :validating="validating"
        @change="onInput($event, field.key)"
      />
      <IdentityState
        v-if="field.key === 'state' && (values.country === 'US' || values.country === 'CA')"
        class="tw-mt-2"
        :states="states[values.country]"
        :field="field"
        :value="values.state"
        :country="values.country"
        :validating="validating"
        @change="onInput($event, field.key)"
      />
      <IdentityRules
        v-if="field.key === 'rules' || field.key === 'gdpr'"
        class="tw-mt-2"
        :field="field"
        :value="values[field.key]"
        :is-dark="isDark"
        :validating="validating"
        @change="onInput($event, field.key)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { countries } from '@/config/countries.config'
import { states } from '@/config/states.config'
import IdentityFirstName from '@/components/Identity/FirstName.vue'
import IdentityLastName from '@/components/Identity/LastName.vue'
import IdentityPhone from '@/components/Identity/Phone.vue'
import IdentityEmail from '@/components/Identity/Email.vue'
import IdentityBirthday from '@/components/Identity/Birthday.vue'
import IdentityRules from '@/components/Identity/Rules.vue'
import IdentityCountry from '@/components/Identity/Country.vue'
import IdentityState from '@/components/Identity/State.vue'

export default {
  name: 'Identity',
  components: {
    IdentityEmail,
    IdentityBirthday,
    IdentityRules,
    IdentityCountry,
    IdentityState,
    IdentityFirstName,
    IdentityLastName,
    IdentityPhone,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    isDark: {
      type: Boolean,
      required: true,
    },
    values: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    validating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    menu: false,
    identity: {},
    countries,
    states,
  }),
  computed: {
    ...mapState({
      currentLocale: state => state.global.currentLocale,
      userCountry: state => state.global.user.country,
    }),
  },
  methods: {
    onReset(key) {
      delete this.identity[key]
      this.identity = {
        ...this.values,
      }
      this.$emit('change', this.identity)
    },
    onInput(e, key) {
      this.menu = false
      if (key === 'country') {
        delete this.identity.state
      }
      this.identity = {
        ...this.values,
        [key]: typeof e === 'string' ? e.trim() : e,
      }
      this.$emit('change', this.identity)
    },
  },
}
</script>
